/* eslint-disable */
import axios from 'axios';
import dayjs from 'dayjs';
import cookies from 'js-cookie';
import { ref } from 'vue';
import type {
  LocaleMessages,
  Path,
  VueMessageType,
} from 'vue-i18n';
import { Cookie } from '@/types';
import { createI18n } from 'vue-i18n';
import { errorLogger } from '@/utils/services/errorLogging';

import { isHapiError } from './hapiService';
import type { LanguageType, TLanguageCode } from '@/types';
import { LANGUAGE_CODE } from '@/types';

const messages: LocaleMessages<VueMessageType> = {};
const loadedLanguages: Array<string> = [];
const languageSelected: LanguageType =
  (cookies.get('language') as LanguageType) || 'en_GB';
let initialLanguageLoaded = false;
export const slugLanguageSelected = ref(cookies.get(Cookie.SLUG_LANGUAGE_SELECTED) === 'true');

setDayjsLanguage(languageSelected);

let slugsMessagesMap: Record<string, string> = {}

let missingLogged = false;
export const i18n = createI18n({
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  warnHtmlInMessage: 'off',
  locale: languageSelected,
  missing: (locale: string, slug: string) => {
    if (cookies.get('log_missing_translations')) {
      console.log('missing translation: ', locale, slug);
    }
  
    if(slug.includes('v2.') && !missingLogged && initialLanguageLoaded) {
      errorLogger.logError(new Error(`Missing translation for slug: ${slug}, language: ${locale}`));
      missingLogged = true;
    }

    const oldMessage = slugsMessagesMap[slug];
    const selectedLanguageMessages = (messages[languageSelected] || {}) as unknown as Record<string, string>;

    if(selectedLanguageMessages[oldMessage]) {
      return selectedLanguageMessages[oldMessage];
    }

    const enGbMessages = (messages[LANGUAGE_CODE.en_GB] || {}) as unknown as Record<string, string>;

    return enGbMessages[slug] || oldMessage || slug; 
  },
  //@ts-ignore
  messages,
}).global;


const getMessagesByLanguage = async (language: TLanguageCode) => {
  const messages = await import(`../../../translations/${language}.js`);

  if (process.env.NODE_ENV === 'development') {
    // Ignore TS as type declarations are not necessary for this file
    //@ts-ignore
    const localMessages = await import(`../../../translations-dev.js`);

    return {...messages.default[language], ...localMessages.default};
  }

  return messages.default[language];
}

const setMessagesByLanguage = async (language: TLanguageCode) => {
  const messages = await getMessagesByLanguage(language);

  i18n.setLocaleMessage(
    language,
    messages,
  );

  loadedLanguages.push(language);
}

const initSlugsMessageMap = async () => {
  //@ts-ignore
  slugsMessagesMap = (await import(`../../../translations/slugs-message-map.json`)).default;
}

export const initialMessagesLoad = async (language: TLanguageCode) => {
  try {
    await initSlugsMessageMap();
    if(language !== LANGUAGE_CODE.en_GB) {
      //load english for fallback messages
      await setMessagesByLanguage(LANGUAGE_CODE.en_GB);
    }

    await setMessagesByLanguage(language);

    setI18nLanguage(language);
    initialLanguageLoaded = true;
  } catch (e) {
    //error
  }
}

function setI18nLanguage(language: string): string {
  i18n.locale = language;
  axios.defaults.headers['Accept-Language'] = language;
  setLanguageCookie(language);

  return language;
}

function setLanguageCookie(language: string): void {
  const [subdomain] = location.host.split('.');
  const domain = location.host.replace(subdomain, '');

  cookies.remove('language');
  errorLogger.setTag(Cookie.LANGUAGE, language);
  cookies.set(Cookie.LANGUAGE, language, { expires: 365, domain });
}

function setDayjsLanguage(language: string): void {
  let currentLang = language;
  if (language === 'ar_AR') currentLang = 'en_GB';
  dayjs.locale(currentLang);
}

function approveTranslation(key: Path): boolean {
  if (!isNaN(Number(key))) return false;
  if (isErrorMessageWithNumber(key)) return false;
  if (isGeneralNonTranslatable(key)) return false;
  if (isAddressFromMessage(key)) return false;
  if (isApplicationError(key)) return false;
  if (containsUsername(key)) return false;
  if (doesContainPath(key)) return false;
  if (isHapiMessage(key)) return false;
  if (badGetRequest(key)) return false;
  if (containsError(key)) return false;
  if (isVPSMessage(key)) return false;
  if (isSQLMessage(key)) return false;
  if (failedSetups(key)) return false;
  if (!hasLetters(key)) return false;
  if (isInvoice(key)) return false;

  return true;
}

function isErrorMessageWithNumber(key: Path): boolean {
  const cases = [/^#[0-9]+\s/gim, /Order [0-9]+ service\s*was not found\./];

  return !!cases.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(key);
  });
}

function isGeneralNonTranslatable(key: Path): boolean {
  const regs = [
    /Your ((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])'s DNS is/gim,
  ];

  return !!regs.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(key);
  });
}

function isAddressFromMessage(key: Path): boolean {
  const pattern = /^Address from is invalid:\s/gim;
  const regexp = new RegExp(pattern);

  return regexp.test(key);
}

function isApplicationError(key: Path): boolean {
  const cases = [/on\sline\s/gim, /Argument\s[0-9]+\spassed\sto/gim];

  return !!cases.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(key);
  });
}

function containsUsername(key: Path): boolean {
  const pattern = /u[\d]{9}/gm;
  const regexp = new RegExp(pattern);

  return regexp.test(key);
}

function doesContainPath(key: Path): boolean {
  const paths = [/App\\Services/gim, /home\//gim, /releases\//gim];

  return !!paths.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(key);
  });
}

function isHapiMessage(key: Path): boolean {
  return isHapiError(key);
}

function badGetRequest(key: Path): boolean {
  const pattern = /GET request to endpoint/gm;
  const regexp = new RegExp(pattern);

  return regexp.test(key);
}

function containsError(key: Path): boolean {
  const pattern = /Error:/gim;
  const regexp = new RegExp(pattern);

  return regexp.test(key);
}

function isVPSMessage(key: Path): boolean {
  const regs = [/vps[0-9]+/gim];

  return !!regs.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(key);
  });
}

function isSQLMessage(key: Path): boolean {
  const pattern = /^SQLSTATE/gim;
  const regexp = new RegExp(pattern);

  return regexp.test(key);
}

function failedSetups(key: Path): boolean {
  const regs = [/Failed Setup:/gim];

  return !!regs.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(key);
  });
}

function hasLetters(key: Path): boolean {
  const pattern = /[a-z]/gim;
  const regexp = new RegExp(pattern);

  return regexp.test(key);
}

function isInvoice(key: Path): boolean {
  const pattern = /[A-Z]{2,6}[\d]{2,}\s[A-Za-z]{4,8}/g;
  const regexp = new RegExp(pattern);

  return regexp.test(key);
}

export function $t(...args: any[]) {
  if (args[0] === undefined || args[0] === null) return '';
  if (slugLanguageSelected.value) {
    return args[0] as string;
  }

  let result = '';
  try {
    //@ts-ignore
    result = i18n.t(...args);
  } catch (e) {
    errorLogger.setTag('translationFailure', args[0]?.substring?.(0, 128))
    errorLogger.logError('Failed to translate message')
    errorLogger.setTag('translationFailure', undefined)
    return ''
  } 

  if (result.includes(`{'@'}`)) result = result.replace(`{'@'}`, '@');
  if (result.includes(`{'$'}`)) result = result.replace(`{'$'}`, '$');
  if (result.includes(`{'#'}`)) result = result.replace(`{'#'}`, '#');
  if (result.includes(`{'|'}`)) result = result.replace(`{'|'}`, '|');
  if (result.includes(`{'{'}`)) result = result.replace(`{'{'}`, '{');
  if (result.includes(`{'}'}`)) result = result.replace(`{'}'}`, '}');

  const matches = result.match(/\{(.*?)\}/gim);

  if (matches) {
    matches.forEach((param: any) => {
      if (/{'@'}|{'\$'}|{'#'}|{'\|'}/gm.test(param) || !args[1]) return;

      result = result.replace(param, args[1][param.replace(/({|})/gm, '')]);
    });
  }

  return result;
}

export async function loadLanguageAsync(language: TLanguageCode) {
  if (i18n.locale === language || loadedLanguages.includes(language)) {
    return Promise.resolve(setI18nLanguage(language));
  }

  await setMessagesByLanguage(language);
  return setI18nLanguage(language);
}

export function filterKey(message: string) {
  const currMessage = message?.replace(/\sdata-v-[^(="")]+=""/gim, '');

  return currMessage;
}
