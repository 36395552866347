import {
  NEXT_BEST_ACTION,
  type IDomainStatusInput,
  type NextBestAction,
} from '@hostinger/hdomains-status';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import DomainNsFlowModal from '@/components/Modals/HModal/DomainNsFlowModal.vue';
import TemporaryDomainDisclaimerModal from '@/components/Modals/HModal/TemporaryDomainDisclaimerModal.vue';
import { useGlobals, useModal } from '@/composables';
import { useDomainPurchase } from '@/composables/hDomains/useDomainPurchase';
import { useDomainsStatusStore, useProfileStore } from '@/stores';
import type {
  IActionParamType,
  IDomainNextBestAction,
  IHiddenDomainStatus,
} from '@/types';
import {
  Redirect,
  AmplitudeEvent,
  AmplitudeLocation,
  HIcon,
  Route,
  Website,
} from '@/types';
import { getSplittedDomainParts, toASCII } from '@/utils/helpers';
import { openRenewNowModal } from '@/utils/services/domains/hDomains';
import { errorLogger } from '@/utils/services/errorLogging';

export const defaultSupportedActions = [
  NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY,
  NEXT_BEST_ACTION.MANAGE,
  NEXT_BEST_ACTION.TRANSFER_DOMAIN,
  NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
  NEXT_BEST_ACTION.BUY_DOMAIN,
  NEXT_BEST_ACTION.MOVE_DOMAIN,
  NEXT_BEST_ACTION.RENEW_DOMAIN,
  NEXT_BEST_ACTION.RESTORE_DOMAIN,
  NEXT_BEST_ACTION.FINISH_REGISTRATION,
  NEXT_BEST_ACTION.CONNECT_DOMAIN,
  NEXT_BEST_ACTION.CONTACT_SUPPORT,
];
export const HIDDEN_STATUS_STORAGE_KEY = 'hidden-domain-status';
const CLOUDFLARE_NAMESERVER = '.cloudflare.com';

export const useDomainStatus = (
  domain: string,
  supportedActions: NextBestAction[],
  orderId?: string,
) => {
  const store = useStore();
  const domainStatusStore = useDomainsStatusStore();
  const profileStore = useProfileStore();
  const router = useRouter();
  const route = useRoute();
  const hiddenDomainStatusList = ref<IHiddenDomainStatus>({});
  const { amplitudeV2, t } = useGlobals();
  const { openModal } = useModal();
  const { openDomainPurchaseModal, openRecreateDomainFromRedemptionModal } =
    useDomainPurchase();
  const SUSPENDED_DOMAIN_HELP_PAGE_LINK =
    'https://hpanel.hostinger.com/help?ref=/&topic=website_issues';

  const domainRef = ref(toASCII(domain));

  const domainWithoutSubdomain = computed(() =>
    domainStatusStore.getDomainWithoutSubdomain(domainRef.value),
  );

  const domainStatus = computed(() => {
    const fetchedDomainStatus = domainStatusStore.getDomainStatus(
      domainRef.value,
    );

    const { domainStatus } = fetchedDomainStatus || {};
    const { nextBestAction } = domainStatus || {};

    if (!nextBestAction) {
      return fetchedDomainStatus;
    }

    const isNextBestActionSupported =
      supportedActions?.includes(nextBestAction);

    if (isNextBestActionSupported) {
      return fetchedDomainStatus;
    }

    errorLogger.logError('Fallback next best action was returned');

    return {
      ...fetchedDomainStatus,
      domainStatus: {
        ...domainStatus,
        nextBestAction: NEXT_BEST_ACTION.MANAGE,
      },
    };
  });

  const hResourceId = computed(
    () =>
      domainStatus.value?.additionalDetails.domainResource?.additionalDetails
        ?.hresourceId,
  );

  const domainStatusInputObject = ref<IDomainStatusInput>({
    currentClientId: profileStore.account?.id ?? '',
    domain: domainRef.value,
  });

  const domainSubscriptionByProperty = computed(
    () =>
      store.getters['subscriptions/getDomainSubscriptionByProperty']({
        hResourceId: hResourceId.value,
      }) || {},
  );

  const isDomainLoading = computed(() =>
    domainStatusStore.isDomainInLoadingState(domainRef.value),
  );

  const isUsingCFNameservers = computed(() => {
    const nameservers =
      domainStatus.value?.additionalDetails?.domainPointingStatus?.nameservers;

    return (
      nameservers?.some((ns) => ns.includes(CLOUDFLARE_NAMESERVER)) || false
    );
  });

  const updateDomainStatusHiddenList = () => {
    const domainStatusList = localStorage.getItem(HIDDEN_STATUS_STORAGE_KEY);
    const parsedHiddenDomainStatusList = domainStatusList
      ? (JSON.parse(domainStatusList) as IHiddenDomainStatus)
      : {};

    hiddenDomainStatusList.value = parsedHiddenDomainStatusList;
  };

  const addDomainStatusToHiddenList = (
    domain: string,
    action: NextBestAction,
  ) => {
    updateDomainStatusHiddenList();
    hiddenDomainStatusList.value = {
      ...hiddenDomainStatusList.value,
      [domain]: {
        [action as string]: true,
      },
    };

    localStorage.setItem(
      HIDDEN_STATUS_STORAGE_KEY,
      JSON.stringify(hiddenDomainStatusList.value),
    );
  };

  const hiddenDomainStatusByDomain = computed(
    () => hiddenDomainStatusList.value[domainRef.value],
  );

  const openRestoreDomainModal = () => {
    openRecreateDomainFromRedemptionModal({
      domain: domainWithoutSubdomain.value,
      subscriptionId: domainSubscriptionByProperty.value.id,
      onSuccess: refetchDomainStatus,
    });
  };

  const openRenewDomainModal = (
    props?: { amplitudeSource?: string } & IActionParamType,
  ) => {
    openRenewNowModal({
      domainName: domainWithoutSubdomain.value,
      subscription: domainSubscriptionByProperty.value,
      onSuccess: () => refetchDomainStatus(15000),
      ...props,
    });
  };

  const refetchDomainStatus = async (callAfter?: number) => {
    await domainStatusStore.fetchDomainStatus(
      domainStatusInputObject.value,
      true,
      callAfter,
    );
  };

  const openBuyDomainModal = (location?: string) => {
    openDomainPurchaseModal(domainWithoutSubdomain.value, {
      onOfferChosen: () => {
        if (!location) {
          return;
        }

        const [sld, tld] = getSplittedDomainParts(domainWithoutSubdomain.value);
        amplitudeV2(AmplitudeEvent.Hosting.CROSS_SELL_OFFER_CHOSEN, {
          location,
          tld,
          sld,
        });
      },
      onSuccess: async () => {
        await refetchDomainStatus();
        router.push({
          name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
          params: {
            domain: toASCII(domainWithoutSubdomain.value),
          },
          query: { redirect: redirectLocation.value },
        });
      },
      title: t('Buy domain - {domain}', {
        domain: domainWithoutSubdomain.value,
      }),
    });
  };

  const redirectToAddDomainFlow = (type: Website.Type, websiteUid?: string) => {
    router.push({
      name: Route.Domain.ADD_DOMAIN,
      params: {
        domain: domainRef.value,
      },
      query: {
        websiteType: type,
        redirectUrl: location.href,
        websiteUid,
      },
    });
  };

  const handleTemporaryDomainConnect = (
    websiteType: Website.Type,
    websiteUid?: string,
  ) => {
    openModal({
      component: { TemporaryDomainDisclaimerModal },
      data: {
        onSuccess: () => {
          redirectToAddDomainFlow(websiteType, websiteUid);
        },
      },
    });
  };

  const openDomainNsFlowModal = async () => {
    const id =
      orderId ||
      store?.getters.getCurrentAccountWithDomain(domainRef.value)?.orderId ||
      '';

    openModal({
      component: { DomainNsFlowModal },
      data: {
        domain: domainRef.value,
        orderId: id ?? '',
        onSuccess: () => {
          store.commit('SET_DOMAIN_NOT_POINTING_HIDDEN', true);
          refetchDomainStatus();
        },
      },
    });
  };
  const redirectLocation = computed(() =>
    route.path.includes(Route.Base.WEBSITES)
      ? Route.Websites.WEBSITE_DASHBOARD
      : '',
  );

  const openActionRelatedModal = async (action: Redirect.Action) => {
    switch (action) {
      case Redirect.Action.CONNECT_DOMAIN:
        openDomainNsFlowModal();
        break;
      case Redirect.Action.RENEW_DOMAIN:
        openRenewDomainModal();
        break;
      case Redirect.Action.RESTORE_DOMAIN:
        openRestoreDomainModal();
        break;
      case Redirect.Action.BUY_DOMAIN:
        openBuyDomainModal();
        break;
    }
  };

  const domainNextBestActionMap: Record<
    NextBestAction,
    IDomainNextBestAction | null
  > = {
    [NEXT_BEST_ACTION.CONTACT_SUPPORT]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'danger',
        text: 'Domain is suspended',
        actionText: 'Contact support',
      },
      action: () => {
        window.open(SUSPENDED_DOMAIN_HELP_PAGE_LINK, '_blank');
      },
    },
    [NEXT_BEST_ACTION.MANAGE]: null,
    [NEXT_BEST_ACTION.FINISH_REGISTRATION]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'warning-dark',
        text: 'Domain registration pending',
        actionText: 'Finish registration',
      },
      action: () => {
        router.push({
          name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
          params: {
            domain: toASCII(domainWithoutSubdomain.value),
          },
          query: { redirect: redirectLocation.value },
        });
      },
    },
    [NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY]: {
      props: {
        icon: HIcon.ICON_CLOCK_CIRCLE_24,
        iconColor: 'gray',
        text: 'Temporary domain',
        actionText: 'Connect',
      },
      action: (params) => {
        amplitudeV2(AmplitudeEvent.Websites.WEBSITES_CONNECT_DOMAIN_ENTER, {
          location: AmplitudeLocation.Domain.TEMPORARY_DOMAIN,
        });

        if (params?.websiteType) {
          params.websiteType !== Website.Type.WORDPRESS &&
          params.websiteType !== Website.Type.BUILDER
            ? handleTemporaryDomainConnect(params.websiteType)
            : redirectToAddDomainFlow(params.websiteType, params.websiteUid);
        }
      },
    },
    [NEXT_BEST_ACTION.CONNECT_DOMAIN]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'danger',
        text: 'Domain not connected',
        actionText: 'Connect',
      },
      action: () => {
        // TODO: uncomment after fix for autoconnect domains is done
        // amplitudeV2(AmplitudeEvent.Websites.WEBSITES_CONNECT_DOMAIN_ENTER, {
        //   location: AmplitudeLocation.Domain.NOT_TEMPORARY_DOMAIN,
        // });

        // openAutoConnectModal(domainWithoutSubdomain.value, refetchDomainStatus);

        amplitudeV2(
          AmplitudeEvent.Websites.WEBSITES_CONNECT_EXTERNAL_DOMAIN_ENTER,
        );

        openDomainNsFlowModal();
      },
    },
    [NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL]: {
      props: {
        icon: HIcon.ICON_ERROR,
        iconColor: 'danger',
        text: 'Domain not connected',
        actionText: 'Connect',
      },
      action: () => {
        amplitudeV2(
          AmplitudeEvent.Websites.WEBSITES_CONNECT_EXTERNAL_DOMAIN_ENTER,
        );
        openDomainNsFlowModal();
      },
    },
    [NEXT_BEST_ACTION.TRANSFER_DOMAIN]: {
      props: {
        icon: HIcon.ICON_MIGRATE,
        iconColor: 'gray',
        text: 'Transfer your domain to Hostinger',
        actionText: 'Transfer',
        isCapsuleCloseVisible: true,
      },
      closeAction: () => {
        addDomainStatusToHiddenList(
          domainRef.value,
          NEXT_BEST_ACTION.TRANSFER_DOMAIN,
        );
      },
      action: () => {
        router.push({
          name: Route.Domain.TRANSFER_DOMAIN,
          query: {
            domain: toASCII(domainWithoutSubdomain.value),
          },
        });
      },
    },
    [NEXT_BEST_ACTION.RENEW_DOMAIN]: {
      props: {
        iconColor: 'danger',
        icon: HIcon.ICON_ERROR,
        text: 'Domain has expired',
        actionText: 'Renew',
      },
      action: openRenewDomainModal,
    },
    [NEXT_BEST_ACTION.RESTORE_DOMAIN]: {
      props: {
        iconColor: 'danger',
        icon: HIcon.ICON_ERROR,
        text: 'Domain has expired',
        actionText: 'Restore',
      },
      action: openRestoreDomainModal,
    },
    [NEXT_BEST_ACTION.BUY_DOMAIN]: {
      props: {
        iconColor: 'gray',
        icon: HIcon.ICON_INFO,
        text: 'Domain is available',
        actionText: 'Buy domain',
      },
      action: (params) => {
        openBuyDomainModal(params?.location);
      },
    },
    [NEXT_BEST_ACTION.MOVE_DOMAIN]: null,
  };

  const domainAction = computed(() => {
    const nextBestAction = domainStatus.value?.domainStatus?.nextBestAction;

    if (!nextBestAction || !domainNextBestActionMap[nextBestAction]) {
      return null;
    }

    return domainNextBestActionMap[nextBestAction];
  });

  const isDomainNotConnected = computed(() =>
    (
      [
        NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY,
        NEXT_BEST_ACTION.CONNECT_DOMAIN,
        NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
      ] as NextBestAction[]
    ).includes(
      domainStatus.value?.domainStatus?.nextBestAction as NextBestAction,
    ),
  );

  updateDomainStatusHiddenList();

  return {
    domainNextBestActionMap,
    domainAction,
    domainStatusInputObject,
    domainStatus,
    domainSubscriptionByProperty,
    openRenewDomainModal,
    openActionRelatedModal,
    hiddenDomainStatusByDomain,
    isDomainLoading,
    refetchDomainStatus,
    isDomainNotConnected,
    isUsingCFNameservers,
  };
};
