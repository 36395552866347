import store from '@/store/index';

type WordpressStagingPusherActionData = {
  variables?: {
    request?: {
      domain?: string;
      directory?: string;
    };
    result?: {
      staging_domain?: string;
    };
  };
};
export default (event: string, data?: WordpressStagingPusherActionData) => {
  const variables = data?.variables;
  const coreDetails =
    store.getters.getHostingWordpressSelectedAppData.coreDetails;

  if (!variables?.request && !variables?.result) return;

  store.dispatch('hostingStagingListIndex', {
    domain: coreDetails ? coreDetails.domain : variables?.request?.domain,
    directory: coreDetails
      ? coreDetails.directory
      : variables?.request?.directory,
    withNotification: true,
    stagingDomain: variables.result?.staging_domain
      ? variables.result.staging_domain
      : variables.request?.domain,
    event,
  });
};
