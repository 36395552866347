import type { HostingPlanPerk, HostingPlanPerks } from '@/types';
import { Hosting } from '@/types';
import { mapKeyValue } from '@/utils/helpers/helpers';

const FTP_TOOLTIP =
  'File Transfer Protocol or FTP accounts. You can use these accounts to transfer and store data.';
const WEBSITES_COUNT_TOOLTIP =
  'This is the maximum limit of websites allowed per account, but keep in mind that there are multiple limits for every plan, which should also be taken into consideration. Any of the limits hit affect the whole plan.';
export const WEBSITE_COUNT_TOOLTIP =
  'This is the maximum limit of websites allowed per account, but keep in mind that there are multiple limits for every plan, which should also be taken into consideration. Any of the limits hit affect the whole plan.';
export const WEBSITE_PERFORMANCE_TOOLTIP =
  'Performance is measured by allocated resources to the Hosting plan as well as enabled performance features, such as Hostinger CDN and WordPress Object Cache.';
export const SSL_TOOLTIP =
  "Protect your website and gain customer trust with Let's Encrypt SSL certificates. The certificates encrypt data, making sure it won't be compromised, eavesdropped on, or forged, protecting sensitive user information on your website.";
export const WP_CLI_SSH_TOOLTIP =
  'WordPress Command Line allows you to update plugins, change configurations and much more, without using a web browser.';
export const VISITORS_TOOLTIP =
  "This number shows the approximate traffic your websites can handle. Choose your web hosting plan according to how many visitors you're planning to reach. It is merely a point of reference rather than a restriction, and it depends on the website's resource usage and optimization.";
export const WP_STAGING_TOOLTIP =
  'Test changes in a testing environment before releasing them to the live version of your website';
export const WP_MULTISITE_TOOLTIP =
  'WordPress Multisite enables you to create and run multiple websites using the same WordPress installation on your server. In other words, you can manage several different WordPress websites from a single dashboard.';
const WP_MANAGED_TOOLTIP =
  'Get a list of developer-friendly tools that even newbies find intuitive. A package includes fast and seamless WordPress installation, the easy and quick WP Starter site-building wizard, managed auto-updates, the core software, and plugins, a robust database of WordPress tutorials, friendly support, and more.';
export const SUBDOMAIN_TOOLTIP =
  'You can set up subdomains from aliases as well as the primary domain. Additionally, subdomains may be ‘pointed’ to any subdirectory in the hosting account.';
const FREE_EMAIL =
  'Create up to 100 domain-based email addresses and forwarders at no-cost. This feature comes with a friendly webmail interface where you can manage your accounts, create forwarders, autoresponders, and more. With this plan, you get a separate email service storage limit of 1GB per mailbox.';
export const OBJECT_CACHE_TOOLTIP =
  'Object caching is a process that stores database query results in order to quickly bring them back up the next time they are needed. This can reduce WordPress website response times up to 3 times.';
export const DAILY_BACKUPS_TOOLTIP =
  "Protect your website's data from accidents. We keep daily backups of the last 7 days and weekly backups of the last 7 weeks. A full backup restore is the fastest way to restore your site and limit the effects of website downtime on your site traffic.";
export const WEEKLY_BACKUPS_TOOLTIP =
  "Protect your website's data from accidents. We keep daily backups of the last 7 days and weekly backups of the last 7 weeks. A full backup restore is the fastest way to restore your site and limit the effects of website downtime on your site traffic.";
export const DAILY_ON_DEMAND_BACKUPS_TOOLTIP =
  'Protect your website data with automatic daily and on-demand backups. Daily backups are stored for 7 days, and weekly backups are stored for 7 weeks.';
export const WORDPRESS_AUTOUPDATES_TOOLTIP =
  'Seamlessly safeguard your site with pre-update backups and customizable security adjustments, ensuring a balance between protection and stability, all effortlessly managed by our system.';
export const CPU_CORES_TOOLTIP = 'The amount of CPU power given to the server.';
export const BANDWIDTH_TOOLTIP =
  'Make the most of your web hosting without worrying whether your site’s traffic exceeds bandwidth limits. You can transfer as much data as you need to build an online presence for your business. Although all uses of hosting services provided by Hostinger are subject to our Terms of Service, we hope you will use bandwidth only for the regular operation of a personal or small to medium business website.';
export const DEDICATED_IP_TOOLTIP =
  'Increased security: With a dedicated IP address, you are less likely to be affected by security issues on other websites that share your IP address.</br>Improved email deliverability: Some email providers may block emails coming from shared IP addresses due to the increased risk of spam. Having a dedicated IP address can improve the deliverability of emails sent from your website.</br>Enhanced control: With a dedicated IP address, you have more control over your hosting environment, which can be useful for certain types of websites or applications.';
const WP_OPTIMIZED_TOOLTIP =
  'Get more speed, better SEO, visitor retention and conversions with our custom-built WP optimization stack and LiteSpeed cache.';
export const STORAGE_TOOLTIP =
  'The amount of allocated storage space on high speed Solid State Drives (SSDs).';
const CUSTOMER_SUPPORT_TOOLTIP =
  'You can transfer as much data as you need to build an online presence for your business. Although all uses of hosting services provided by Hostinger are subject to our Terms of Service, we hope you will use bandwidth only for the regular operation of a personal or small to medium business website.';
const UNMETERED_TRAFFIC_TOOLTIP =
  'You can transfer as much data as you need to build an online presence for your business. Although all uses of hosting services provided by Hostinger are subject to our Terms of Service, we hope you will use bandwidth only for the regular operation of a personal or small to medium business website.';
export const MIGRATION_TOOLTIP =
  'Migration: We will move your website to Hostinger from another hosting provider for free.';
export const FREE_DOMAIN_TOOLTIP =
  'Register a domain for free for one year when purchasing a yearly (or longer) hosting plan. Additional terms apply, as not all TLDs are eligible for this promotion. Free domain must be registered within 12 months of acquiring the hosting plan, or it will be cancelled.';
export const FREE_CDN_TOOLTIP =
  "Hostinger's CDN (Content Delivery Network) optimizes website speed by caching content on multiple servers globally and delivering it from the visitor's nearest location, resulting in up to 40% faster loading times. Hostinger CDN comes with built-in static website caching, cache purging, CSS and JavaScript minification, data center rerouting, CDN bypass mode, and other website security features.";
export const WORDPRESS_AI_TOOLS_TOOLTIP =
  'Our in-house developed AI tools help you create unique content for your website. Simply describe what you need, and publish your website fast. Image tool coming soon.';
export const PHP_WORKERS_LIMIT_TOOLTIP =
  'Amount of requests that can be served simultaneously by web server';
export const FILES_AND_DIRECTORIES_TOOLTIP =
  'Items that correspond to the number of files and folders you have on your account';
export const BASIC_WOOCOMMERCE_TOOLTIP =
  'Recommended for basic WooCommerce stores with up to 100 product listings and 100 orders/month. Limited 3rd party (stock management, order fulfilling, invoicing software, reporting) integrations.';
export const STANDARD_WOOCOMMERCE_TOOLTIP =
  'Recommended for most WooCommerce stores with up to 1 000 product listings and 500 orders/month. Multiple 3rd party (stock management, order fulfilling, invoicing software, reporting) integrations.';
export const OPTIMIZED_FOR_WOOCOMMERCE_TOOLTIP =
  'Supports stores that sell up to 500 products, with features such as special product types (bookings, subscriptions, memberships), multiple languages, multiple payment gateways, and advanced shipping calculation logic + several additional free plugins.';
export const STARTER_WOOCOMMERCE_TOOLTIP =
  'Limited support, suitable for small stores selling up to 10 products.';
export const BASIC_WOOCOMMERCE_OPTIMIZATION_TOOLTIP =
  'Supports stores that sell up to 100 products, with features such as standard product types, a single language storefront, a single payment gateway, and simple shipping calculation logic.';
export const ADVANCED_WOOCOMMERCE_TOOLTIP =
  'Recommended for advanced WooCommerce stores with up to 2 000 product listings and 1 000 orders/month. Multiple 3rd party (stock management, order fulfilling, invoicing software, reporting) integrations.';
export const EXPERT_WOOCOMMERCE_TOOLTIP =
  'Recommended WooCommerce stores that require maximum performance. Up to 5 000 product listings and 10 000 orders/month. Multiple 3rd party (stock management, order fulfilling, invoicing software, reporting) integrations.';
export const WEBSITE_BUILDER_WEBSITE_COUNT_TOOLTIP =
  'The maximum amount of websites allowed per account.';
export const WEBSITE_BUILDER_FREE_EMAIL_TOOLTIP =
  'Create up to 100 email addresses and forwarders at no extra cost. Each mailbox comes with a 1GB storage limit.';
export const WEBSITE_BUILDER_FREE_DOMAIN_TOOLTIP =
  'Purchase our website builder plan for 12 months or longer, and claim your free domain name for a year. Options range from popular extensions like .com and .net to niche-specific TLDs like .store, .live, and .tech.';
export const WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP =
  "We care about clients' success. Contact our customer support via our 24/7 live chat.";
export const WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP =
  'Communicate with customers more efficiently, optimize campaigns, and track website data with third-party marketing tools, including Google Analytics, Facebook Pixel, WhatsApp, Google AdSense, and much more.';
export const WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP =
  'Buy our services risk-free and try it with peace of mind. If something goes wrong, you can get a full refund of your hosting fees (excluding domain registration fees) within 30 days of purchasing a plan.';
export const WEBSITE_BUILDER_MOBILE_EDITING_TOOLTIP =
  "Create, edit, publish and maintain your website seamlessly on both mobile and desktop devices. Make changes to your site on the go, ensuring it's always up to date.";
export const WEBSITE_BUILDER_AI_WEBSITE_BUILDER_TOOLTIP =
  'Al Website Builder enables you to launch a website in minutes. Answer three questions, and Al Website Builder creates your site with SEO-friendly content, unique copy, and fitting images. Take it a step further by personalizing your site using drag-and-drop editor.';
export const WEBSITE_BUILDER_AI_IMAGE_GENERATOR_TOOLTIP =
  'Make your website stand out with Al-crafted visuals. First five images are on the house!';
export const WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP =
  'Sell up to 500 products, manage orders and stocks, set up shipping options, and enable appointment bookings conveniently in one place.';
export const WEBSITE_BUILDER_TRANSACTIONS_FEES_TOOLTIP =
  "Your online store's profits are 100% yours – we don't charge any transaction fees.";
export const WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP =
  "The payment methods available for your store depend on where you're selling, your location, currency, and who you're selling to.";
export const PHP_WORKERS_TOOLTIP =
  'PHP workers are directly responsible for loading products, processing orders, and handling customer requests. The more PHP workers available, the faster and more efficiently these tasks are performed, leading to a better shopping experience for customers.';
export const MYSQL_MAX_CONNECTIONS_TOOLTIP =
  'This limit specifies maximum number of simultaneous connections to database.';
export const WP_VULNERABILITIES_SCANNER_TOOLTIP =
  'Scans your site and alerts about vulnerabilities in the WordPress core software, plugins and themes.';
export const WP_STANDARD_ACCELERATION_TOOLTIP =
  'We use LiteSpeed Cache for WordPress (LSCWP). It uses server-level cache with optimization features and can greatly improve website loading time.';
export const WP_ADVANCED_ACCELERATION_TOOLTIP =
  'LiteSpeed Cache + Object Cache for WordPress. The LiteSpeed object caching system stores database query results and retrieves the information from the cache instead of reloading it.';
export const PRE_BUILT_TEMPLATES_TOOLTIP =
  'A variety of ready-to-go customizable website templates to help you get started.';
export const WP_COMPATIBILITY_CHECKER_TOOLTIP =
  'Automatic compatibility checks provide preventive solutions to issues that could otherwise result in sites crashing or slowing down.';
export const AI_CONTENT_CREATOR_TOOLTIP =
  'Turns your idea into bespoke content in a blink of an eye.';
export const WP_AI_TROUBLESHOOTER_TOOLTIP =
  'Detects and fixes website errors for you.';
export const AI_ASSISTANT_TOOLTIP =
  'On-the-spot support directly within the WordPress Admin panel.';
export const CLOUDFLARE_PROTECTED_NS_TOOLTIP =
  'Shield your DNS infrastructure from DDoS attacks. Cloudflare DNS firewall protects our nameservers, and it has the same DDoS protection that has mitigated some of the largest DDoS attacks to date. When malicious traffic is aimed at our origin nameservers, Cloudflare’s DDoS protection reroutes that traffic and absorbs it across global networks. That means your website will be available and traffic continues to flow, even when your origin nameservers are compromised.';
export const ACCESS_MANAGER_TOOLTIP =
  'Collaborate efficiently on your web hosting account. With this tool, you can manage privileges for others to access your web hosting account (or just specific services).';
export const UPTIME_GUARANTEE_TOOLTIP =
  'Maximize your presence online. We keep our annual uptime rates at a 99.9% height to ensure accessibility of your website at all times. When your website’s down, that’s a potentially missed opportunity to engage with a customer (or potential customer). That’s why we guarantee you one of the best service uptimes globally.';
export const CRONJOBS_TOOLTIP =
  'Schedule commands or scripts on a server, and run them periodically at fixed times, dates, or intervals.';
export const GIT_ACCESS_TOOLTIP =
  'Use the Git source control system to handle every project with speed and efficiency. We integrated it in our hosting plans to allow you to create repositories of your website, which you can later access, download, edit, and auto-deploy on multiple local branches using Git.';
export const MULTIPLE_PHP_VERSIONS_TOOLTIP =
  'Switch to your desired version of PHP in just one click. We support versions all the way from PHP 7.3 to the latest version of PHP 8.3.';
export const DNS_MANAGEMENT_TOOLTIP =
  'Be in charge of your records. Hostinger allows you to edit domain name system (DNS), including A, CNAME, MX, NS and other kinds of records.';
export const CACHE_MANAGER_TOOLTIP =
  'A custom built caching solution that enables faster website load times and increased speed.';
export const CONTROL_PANEL_TOOLTIP =
  'A custom-developed control panel built to maximize the potential of your website and service provided for you.';
export const SSH_ACCESS_TOOLTIP =
  'Use SSH to protect your communication with the remote server. It provides a secure encrypted connection to your account to easily manage massive files and even databases. It serves to administrators and web developers to enable direct secure access to their hosting account on the web server. With SSH enabled, you can also take advantage of SFTP for secure file access, transfer, and management.';
export const FREE_AMAZON_AFFILIATE_PLUGIN_TOOLTIP =
  'Straightforward connection to Amazon API.';

export const featureList: HostingPlanPerks = {
  [Hosting.PlanSlug.STARTER]: [
    {
      text: Hosting.Perks.SINGLE_WEBSITE,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
      tooltipValues: { value: '1' },
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.WARNING,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.SHARED_IP_ADDRESS,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 50 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '10 000' },
      state: Hosting.PerkStatus.WARNING,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.ONE_FREE_EMAIL_ACCOUNT,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.ONE_FREE_FTP_ACCOUNT,
      state: Hosting.PerkStatus.WARNING,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.NOT_OPTIMIZED_FOR_WOOCOMMERCE,
      state: Hosting.PerkStatus.DANGER,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.DANGER,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.PREMIUM]: [
    {
      text: Hosting.Perks.SINGLE_WEBSITE,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '100' },
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.SHARED_IP_ADDRESS,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '25 000' },
      state: Hosting.PerkStatus.WARNING,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.BASIC_WOOCOMMERCE_OPTIMIZATION,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.DANGER,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.BUSINESS]: [
    {
      text: Hosting.Perks.SINGLE_WEBSITE,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '100' },
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.SHARED_IP_ADDRESS,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '100 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_ECONOMY]: [
    {
      text: Hosting.Perks.RAM_GB,
      textValues: { count: 3 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.CPU,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '200 000' },
      state: Hosting.PerkStatus.WARNING,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '300' },
      tooltip: WEBSITES_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      isBold: true,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },

    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_PROFESSIONAL]: [
    {
      text: Hosting.Perks.RAM_GB,
      textValues: { count: 6 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.CPU,
      textValues: { count: 4 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 250 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '300 000' },
      state: Hosting.PerkStatus.WARNING,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '300' },
      tooltip: WEBSITES_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      isBold: true,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },

    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_ENTERPRISE]: [
    {
      text: Hosting.Perks.RAM_GB,
      textValues: { count: 12 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.CPU,
      textValues: { count: 6 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '400 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '300' },
      tooltip: WEBSITES_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      isBold: true,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.WP_BUSINESS]: [
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.WARNING,
      tooltipValues: { value: '100' },
      tooltip: WEBSITES_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.WARNING,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.RAM_GB,
      textValues: { count: '1,50' },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.CPU,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.WP_PREMIUM]: [
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.DANGER,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.WARNING,
      tooltipValues: { value: '100' },
      tooltip: WEBSITES_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.WARNING,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.RAM_GB,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.WARNING,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.SINGLE_CPU,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.WP_SINGLE]: [
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.DANGER,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MULTISITE,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
      tooltipValues: { value: '1' },
      tooltip: WEBSITES_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.WARNING,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.RAM_GB,
      textValues: { count: '0,75' },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.WARNING,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 50 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.SINGLE_CPU,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.ONE_FREE_EMAIL_ACCOUNT,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.ONE_FTP_ACCOUNT,
      state: Hosting.PerkStatus.WARNING,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.WP_CLOUD_ECONOMY]: [
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '300' },
      tooltip: WEBSITES_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.RAM_GB,
      textValues: { count: 3 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.CPU,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
  ],
};

export const hostingUpgradeFeatureList: HostingPlanPerks = {
  [Hosting.PlanSlug.STARTER]: [
    {
      text: Hosting.Perks.SINGLE_WEBSITE,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
      tooltipValues: { value: '1' },
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.WARNING,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.SHARED_IP_ADDRESS,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 50 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '10 000' },
      state: Hosting.PerkStatus.WARNING,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.ONE_FREE_EMAIL_ACCOUNT,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.ONE_FREE_FTP_ACCOUNT,
      state: Hosting.PerkStatus.WARNING,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.NOT_OPTIMIZED_FOR_WOOCOMMERCE,
      state: Hosting.PerkStatus.DANGER,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.DANGER,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.PREMIUM]: [
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.SHARED_IP_ADDRESS,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '25 000' },
      state: Hosting.PerkStatus.WARNING,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.Perks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.BASIC_WOOCOMMERCE_OPTIMIZATION,
      state: Hosting.PerkStatus.WARNING,
      tooltip: BASIC_WOOCOMMERCE_OPTIMIZATION_TOOLTIP,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.DANGER,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.BUSINESS]: [
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.SHARED_IP_ADDRESS,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.WARNING,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
      isBold: true,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '100 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
      isBold: true,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE_PERFORMANCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_ECONOMY]: [
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DEDICATED_IP_TOOLTIP,
      isBold: true,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
      isBold: true,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '200 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
      isBold: true,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE_PERFORMANCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_PROFESSIONAL]: [
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DEDICATED_IP_TOOLTIP,
      isBold: true,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 250 },
      state: Hosting.PerkStatus.SUCCESS,
      isBold: true,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '300 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
      isBold: true,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE_PERFORMANCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_ENTERPRISE]: [
    {
      text: Hosting.Perks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.SUBDOMAIN,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.Perks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DEDICATED_IP_TOOLTIP,
      isBold: true,
    },
    {
      text: Hosting.Perks.STORAGE,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      isBold: true,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '400 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
      isBold: true,
    },
    {
      text: Hosting.Perks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.Perks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.Perks.BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_MANAGED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MANAGED_TOOLTIP,
    },
    {
      text: Hosting.Perks.OPTIMIZED_FOR_WOOCOMMERCE_PERFORMANCE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.WP_STAGING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.Perks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
  ],
};

export const HOSTING_OFFER_PERKS_BUSINESS: HostingPlanPerk[] = [
  {
    text: Hosting.Perks.BUILDER,
    state: Hosting.PerkStatus.SUCCESS,
  },
  {
    text: Hosting.Perks.WP_OPTIMIZED,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: WP_OPTIMIZED_TOOLTIP,
  },
  {
    text: Hosting.Perks.FREE_EMAIL,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: FREE_EMAIL,
  },
  {
    text: Hosting.Perks.OBJECT_CACHE,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: OBJECT_CACHE_TOOLTIP,
  },
  {
    text: Hosting.Perks.SSL,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: SSL_TOOLTIP,
  },
  {
    text: Hosting.Perks.UNMETERED_TRAFFIC,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: UNMETERED_TRAFFIC_TOOLTIP,
  },
  {
    text: Hosting.Perks.WEBSITES,
    textValues: { count: 100 },
    state: Hosting.PerkStatus.SUCCESS,
    tooltipValues: { value: '100' },
    tooltip: WEBSITES_COUNT_TOOLTIP,
  },
  {
    text: Hosting.Perks.STORAGE,
    textValues: { count: 200 },
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: STORAGE_TOOLTIP,
  },
  {
    text: Hosting.Perks.WP_MANAGED,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: WP_MANAGED_TOOLTIP,
  },
  {
    text: Hosting.Perks.CUSTOMER_SUPPORT,
    textValues: { count: 100 },
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: CUSTOMER_SUPPORT_TOOLTIP,
  },
];
export const HOSTING_OFFER_PERKS_STARTER: HostingPlanPerk[] = [
  {
    text: Hosting.Perks.NO_CODE_BUILDER,
    state: Hosting.PerkStatus.SUCCESS,
  },
  {
    text: Hosting.Perks.MALWARE_SCANNER,
    state: Hosting.PerkStatus.SUCCESS,
  },
  {
    text: Hosting.Perks.ONE_EMAIL_ACCOUNT,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: FREE_EMAIL,
  },
  {
    text: Hosting.Perks.FREE_WEBSITE_MIGRATION,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: MIGRATION_TOOLTIP,
  },
  {
    text: Hosting.Perks.SSL,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: SSL_TOOLTIP,
  },
  {
    text: Hosting.Perks.UNMETERED_TRAFFIC,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: UNMETERED_TRAFFIC_TOOLTIP,
  },
  {
    text: Hosting.Perks.WEEKLY_BACKUPS,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: WEEKLY_BACKUPS_TOOLTIP,
  },
  {
    text: Hosting.Perks.STORAGE,
    textValues: { count: 50 },
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: STORAGE_TOOLTIP,
  },
  {
    text: Hosting.Perks.WP_MANAGED,
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: WP_MANAGED_TOOLTIP,
  },
  {
    text: Hosting.Perks.CUSTOMER_SUPPORT,
    textValues: { count: 100 },
    state: Hosting.PerkStatus.SUCCESS,
    tooltip: CUSTOMER_SUPPORT_TOOLTIP,
  },
];

export const websiteBuilderUpgradeOfferFeatureList: HostingPlanPerks = {
  [Hosting.PlanSlug.PREMIUM]: [
    {
      text: Hosting.WebsiteBuilderPerks.AI_IMAGE_GENERATOR,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WEBSITE_BUILDER_AI_IMAGE_GENERATOR_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WRITER,
      state: Hosting.PerkStatus.DANGER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_BLOG_GENERATOR,
      state: Hosting.PerkStatus.DANGER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.DANGER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.DANGER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.DANGER,
      tooltip: WEBSITE_BUILDER_TRANSACTIONS_FEES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.DANGER,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_WEBSITE_BUILDER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MOBILE_EDITING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MOBILE_EDITING_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.BUSINESS]: [
    {
      text: Hosting.WebsiteBuilderPerks.AI_IMAGE_GENERATOR,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_IMAGE_GENERATOR_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WRITER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_BLOG_GENERATOR,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.SUCCESS,
    },

    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_TRANSACTIONS_FEES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_WEBSITE_BUILDER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MOBILE_EDITING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MOBILE_EDITING_TOOLTIP,
    },
  ],
};

export const newFeatureList: HostingPlanPerks = {
  [Hosting.PlanSlug.STARTER]: [
    {
      text: Hosting.NewPerks.SINGLE_WEBSITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '10 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SSD_STORAGE,
      textValues: { count: 50 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '200 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SINGLE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEEKLY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_VULNERABILITIES_SCANNER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_STANDARD_ACCELERATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.Perks.BANDWIDTH,
      textValues: { count: '100 GB' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: BANDWIDTH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_CDN,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: FREE_CDN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      title: Hosting.PerkTitles.MANAGED_WORDPRESS,
    },
    {
      text: Hosting.NewPerks.FREE_WORDPRESS_INSTALLATION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PRE_BUILT_TEMPLATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_ACCELERATION_LITESPEED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_VULNERABILITIES_SCANNER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_COMPATIBILITY_CHECKER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_COMPATIBILITY_CHECKER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.STARTER_WOOCOMMERCE,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: STARTER_WOOCOMMERCE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_MULTISITE,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_CLI_AND_SSH,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WP_CLI_SSH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.OBJECT_CACHE,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.ON_DEMAND_BACKUP,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.WEBSITE_BUILDER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_WEBSITE_BUILDER_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_IMAGE_GENERATOR,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_AI_IMAGE_GENERATOR_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WRITER,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_BLOG_GENERATOR,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_TRANSACTIONS_FEES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.SECURITY,
    },
    {
      text: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEB_APPLICATION_FIREWALL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CLOUDFLARE_PROTECTED_NS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CLOUDFLARE_PROTECTED_NS_TOOLTIP,
    },
    {
      text: Hosting.Perks.MALWARE_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.ACCESS_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: ACCESS_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      title: Hosting.PerkTitles.SERVICE_AND_SUPPORT,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UPTIME_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: UPTIME_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GLOBAL_DATA_CENTERS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      title: Hosting.PerkTitles.TECHNICAL_DETAILS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '200 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PHP_WORKERS,
      textValues: { count: 25 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PHP_WORKERS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.VISITORS,
      textValues: { count: '10 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SUBDOMAINS,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
      textValues: { count: 25 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DATABASES,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FTP_SINGLE,
      textValues: { count: 1 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.CRONJOBS,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CRONJOBS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GIT_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: GIT_ACCESS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MULTIPLE_PHP_VERSIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MULTIPLE_PHP_VERSIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DNS_MANAGEMENT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DNS_MANAGEMENT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CACHE_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CACHE_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CONTROL_PANEL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CONTROL_PANEL_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSH_ACCESS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: SSH_ACCESS_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.PREMIUM]: [
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '25 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SSD_STORAGE,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '400 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.NewPerks.WEEKLY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEEKLY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_VULNERABILITIES_SCANNER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_STANDARD_ACCELERATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_BANDWIDTH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: BANDWIDTH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_CDN,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: FREE_CDN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      title: Hosting.PerkTitles.MANAGED_WORDPRESS,
    },
    {
      text: Hosting.NewPerks.FREE_WORDPRESS_INSTALLATION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PRE_BUILT_TEMPLATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_ACCELERATION_LITESPEED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.AUTOMATIC_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_VULNERABILITIES_SCANNER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_COMPATIBILITY_CHECKER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_COMPATIBILITY_CHECKER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.STARTER_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: STARTER_WOOCOMMERCE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_CLI_AND_SSH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_CLI_SSH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.OBJECT_CACHE,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.ON_DEMAND_BACKUP,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.WEBSITE_BUILDER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_WEBSITE_BUILDER_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_IMAGE_GENERATOR,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_AI_IMAGE_GENERATOR_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WRITER,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_BLOG_GENERATOR,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_TRANSACTIONS_FEES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.SECURITY,
    },
    {
      text: Hosting.NewPerks.STANDARD_DDOS_PROTECTION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEB_APPLICATION_FIREWALL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CLOUDFLARE_PROTECTED_NS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CLOUDFLARE_PROTECTED_NS_TOOLTIP,
    },
    {
      text: Hosting.Perks.MALWARE_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.ACCESS_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: ACCESS_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.SERVICE_AND_SUPPORT,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UPTIME_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: UPTIME_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GLOBAL_DATA_CENTERS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      title: Hosting.PerkTitles.TECHNICAL_DETAILS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '400 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PHP_WORKERS,
      textValues: { count: 40 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PHP_WORKERS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.VISITORS,
      textValues: { count: '25 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SUBDOMAINS,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
      textValues: { count: 50 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DATABASES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_CRONJOBS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CRONJOBS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GIT_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: GIT_ACCESS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MULTIPLE_PHP_VERSIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MULTIPLE_PHP_VERSIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DNS_MANAGEMENT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DNS_MANAGEMENT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CACHE_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CACHE_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CONTROL_PANEL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CONTROL_PANEL_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSH_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSH_ACCESS_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.BUSINESS]: [
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.Perks.VISITORS,
      textValues: { count: '100 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.NVME_STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '600 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.NewPerks.DAILY_AND_ON_DEMAND_BACKUP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_VULNERABILITIES_SCANNER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SMART_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_ADVANCED_ACCELERATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_ADVANCED_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_BANDWIDTH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: BANDWIDTH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_CDN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_CDN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.UNAVAILABLE,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      title: Hosting.PerkTitles.MANAGED_WORDPRESS,
    },
    {
      text: Hosting.NewPerks.FREE_WORDPRESS_INSTALLATION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FREE_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PRE_BUILT_TEMPLATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_ACCELERATION_LITESPEED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SMART_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_VULNERABILITIES_SCANNER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_COMPATIBILITY_CHECKER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_COMPATIBILITY_CHECKER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.BASIC_WOOCOMMERCE_OPTIMIZATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: BASIC_WOOCOMMERCE_OPTIMIZATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_CLI_AND_SSH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_CLI_SSH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.ON_DEMAND_BACKUP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.WEBSITE_BUILDER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_WEBSITE_BUILDER_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_IMAGE_GENERATOR,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_IMAGE_GENERATOR_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WRITER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_BLOG_GENERATOR,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_TRANSACTIONS_FEES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.SECURITY,
    },
    {
      text: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEB_APPLICATION_FIREWALL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CLOUDFLARE_PROTECTED_NS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CLOUDFLARE_PROTECTED_NS_TOOLTIP,
    },
    {
      text: Hosting.Perks.MALWARE_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.ACCESS_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: ACCESS_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.SERVICE_AND_SUPPORT,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UPTIME_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: UPTIME_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GLOBAL_DATA_CENTERS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.UNAVAILABLE,
    },
    {
      title: Hosting.PerkTitles.TECHNICAL_DETAILS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '600 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PHP_WORKERS,
      textValues: { count: 60 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PHP_WORKERS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.VISITORS,
      textValues: { count: '100 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SUBDOMAINS,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
      textValues: { count: 75 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DATABASES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_CRONJOBS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CRONJOBS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GIT_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: GIT_ACCESS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MULTIPLE_PHP_VERSIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MULTIPLE_PHP_VERSIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DNS_MANAGEMENT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DNS_MANAGEMENT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CACHE_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CACHE_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CONTROL_PANEL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CONTROL_PANEL_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSH_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSH_ACCESS_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_ECONOMY]: [
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '300' },
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.NVME_STORAGE,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.RAM,
      textValues: { count: 3 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CPU_CORES,
      textValues: { count: 2 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_BANDWIDTH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: BANDWIDTH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_CDN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_CDN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.MANAGED_WORDPRESS,
    },
    {
      text: Hosting.NewPerks.STANDARD_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: STANDARD_WOOCOMMERCE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AUTOMATIC_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_WORDPRESS_INSTALLATION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PRE_BUILT_TEMPLATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_AMAZON_AFFILIATE_PLUGIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_ACCELERATION_LITESPEED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SMART_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WP_COMPATIBILITY_CHECKER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_COMPATIBILITY_CHECKER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_CLI_AND_SSH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_CLI_SSH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.ON_DEMAND_BACKUP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.WEBSITE_BUILDER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DESIGNER_CRAFTED_TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.GOOGLE_ANALYTICS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_COPYWRITING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.SECURITY,
    },
    {
      text: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEB_APPLICATION_FIREWALL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CLOUDFLARE_PROTECTED_NS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.MALWARE_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.ACCESS_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.SERVICE_AND_SUPPORT,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UPTIME_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: UPTIME_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GLOBAL_DATA_CENTERS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.TECHNICAL_DETAILS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '2 000 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PHP_WORKERS,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PHP_WORKERS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.VISITORS,
      textValues: { count: '200 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SUBDOMAINS,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DATABASES,
      textValues: { count: 800 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_CRONJOBS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CRONJOBS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GIT_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: GIT_ACCESS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MULTIPLE_PHP_VERSIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MULTIPLE_PHP_VERSIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DNS_MANAGEMENT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DNS_MANAGEMENT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CACHE_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CACHE_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CONTROL_PANEL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CONTROL_PANEL_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSH_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSH_ACCESS_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_PROFESSIONAL]: [
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '300' },
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.NVME_STORAGE,
      textValues: { count: 250 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.RAM,
      textValues: { count: 6 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CPU_CORES,
      textValues: { count: 4 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_BANDWIDTH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: BANDWIDTH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_CDN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_CDN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.MANAGED_WORDPRESS,
    },
    {
      text: Hosting.NewPerks.ADVANCED_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: ADVANCED_WOOCOMMERCE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AUTOMATIC_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_WORDPRESS_INSTALLATION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PRE_BUILT_TEMPLATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_AMAZON_AFFILIATE_PLUGIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_ACCELERATION_LITESPEED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SMART_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WP_COMPATIBILITY_CHECKER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_COMPATIBILITY_CHECKER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_CLI_AND_SSH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_CLI_SSH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.ON_DEMAND_BACKUP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.WEBSITE_BUILDER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DESIGNER_CRAFTED_TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.GOOGLE_ANALYTICS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_COPYWRITING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.SECURITY,
    },
    {
      text: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEB_APPLICATION_FIREWALL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CLOUDFLARE_PROTECTED_NS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.MALWARE_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.ACCESS_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.SERVICE_AND_SUPPORT,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UPTIME_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: UPTIME_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GLOBAL_DATA_CENTERS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.TECHNICAL_DETAILS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '3 000 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PHP_WORKERS,
      textValues: { count: 200 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PHP_WORKERS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.VISITORS,
      textValues: { count: '300 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SUBDOMAINS,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
      textValues: { count: 125 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DATABASES,
      textValues: { count: 800 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_CRONJOBS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CRONJOBS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GIT_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: GIT_ACCESS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MULTIPLE_PHP_VERSIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MULTIPLE_PHP_VERSIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DNS_MANAGEMENT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DNS_MANAGEMENT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CACHE_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CACHE_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CONTROL_PANEL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CONTROL_PANEL_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSH_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSH_ACCESS_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.CLOUD_ENTERPRISE]: [
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '300' },
      tooltip: WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.NVME_STORAGE,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.RAM,
      textValues: { count: 12 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CPU_CORES,
      textValues: { count: 6 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.DAILY_BACKUPS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DAILY_BACKUPS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_EMAIL,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_SSL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSL_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_BANDWIDTH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: BANDWIDTH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_CDN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_CDN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DEDICATED_IP_ADDRESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DEDICATED_IP_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.MANAGED_WORDPRESS,
    },
    {
      text: Hosting.NewPerks.EXPERT_WOOCOMMERCE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: EXPERT_WOOCOMMERCE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AUTOMATIC_WEBSITE_MIGRATION,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MIGRATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_WORDPRESS_INSTALLATION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.PRE_BUILT_TEMPLATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PRE_BUILT_TEMPLATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_AMAZON_AFFILIATE_PLUGIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FREE_AMAZON_AFFILIATE_PLUGIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_ACCELERATION_LITESPEED,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STANDARD_ACCELERATION_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SMART_WP_UPDATES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AUTOUPDATES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_VULNERABILITIES_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WP_COMPATIBILITY_CHECKER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_COMPATIBILITY_CHECKER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_MULTISITE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_MULTISITE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WP_CLI_AND_SSH,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_CLI_SSH_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_STAGING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WP_STAGING_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.OBJECT_CACHE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: OBJECT_CACHE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.ON_DEMAND_BACKUP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WORDPRESS_AI_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WORDPRESS_AI_TOOLS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.WEBSITE_BUILDER,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DESIGNER_CRAFTED_TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.GOOGLE_ANALYTICS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_COPYWRITING_TOOL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
    {
      title: Hosting.PerkTitles.SECURITY,
    },
    {
      text: Hosting.NewPerks.ENHANCED_DDOS_PROTECTION,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEB_APPLICATION_FIREWALL,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.CLOUDFLARE_PROTECTED_NS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.MALWARE_SCANNER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.ACCESS_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.FREE_WHOIS_PRIVACY,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.SERVICE_AND_SUPPORT,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UPTIME_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: UPTIME_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GLOBAL_DATA_CENTERS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PRIORITY_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      title: Hosting.PerkTitles.TECHNICAL_DETAILS,
    },
    {
      text: Hosting.NewPerks.FILES_AND_DIRECTORIES,
      textValues: { count: '4 000 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FILES_AND_DIRECTORIES_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.PHP_WORKERS,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: PHP_WORKERS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.VISITORS,
      textValues: { count: '400 000' },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: VISITORS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.SUBDOMAINS,
      textValues: { count: 300 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SUBDOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MYSQL_MAX_CONNECTIONS,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MYSQL_MAX_CONNECTIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DATABASES,
      textValues: { count: 800 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.Perks.FTP,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: FTP_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.UNLIMITED_CRONJOBS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CRONJOBS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.GIT_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: GIT_ACCESS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.MULTIPLE_PHP_VERSIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: MULTIPLE_PHP_VERSIONS_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.DNS_MANAGEMENT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: DNS_MANAGEMENT_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CACHE_MANAGER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CACHE_MANAGER_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.CONTROL_PANEL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: CONTROL_PANEL_TOOLTIP,
    },
    {
      text: Hosting.Perks.SSH_ACCESS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: SSH_ACCESS_TOOLTIP,
    },
  ],
  [Hosting.PlanSlug.WEBSITE_BUILDER]: [
    {
      text: Hosting.WebsiteBuilderPerks.PUBLISH_WEBSITE,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.NewPerks.WEBSITES,
      textValues: { count: 100 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltipValues: { value: '100' },
      tooltip: WEBSITE_BUILDER_WEBSITE_COUNT_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.FREE_DOMAIN,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_FREE_DOMAIN_TOOLTIP,
    },
    {
      text: Hosting.NewPerks.FREE_EMAIL,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_FREE_EMAIL_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.CUSTOMER_SUPPORT,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_CUSTOMER_SUPPORT_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MONEY_BACK_GUARANTEE,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MONEY_BACK_GUARANTEE_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.DRAG_AND_DROP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TEMPLATES,
      textValues: { count: 150 },
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MARKETING_INTEGRATIONS,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MARKETING_INTEGRATIONS_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.MOBILE_EDITING,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_MOBILE_EDITING_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WEBSITE_BUILDER,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_WEBSITE_BUILDER_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_IMAGE_GENERATOR,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_AI_IMAGE_GENERATOR_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_WRITER,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_BLOG_GENERATOR,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_HEATMAP,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.AI_SEO_TOOLS,
      state: Hosting.PerkStatus.SUCCESS,
    },
    {
      text: Hosting.WebsiteBuilderPerks.ECOMMERCE_FEATURES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_ECOMMERCE_FEATURES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.TRANSACTIONS_FEES,
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_TRANSACTIONS_FEES_TOOLTIP,
    },
    {
      text: Hosting.WebsiteBuilderPerks.PAYMENT_METHODS,
      textValues: { count: 20 },
      state: Hosting.PerkStatus.SUCCESS,
      tooltip: WEBSITE_BUILDER_PAYMENT_METHODS_TOOLTIP,
    },
  ],
};

export const mapHostingOfferPerks = (key: Hosting.PlanSlug) => {
  const HOSTING_OFFER_PERK_MAP = {
    [Hosting.PlanSlug.BUSINESS]: HOSTING_OFFER_PERKS_BUSINESS,
    [Hosting.PlanSlug.STARTER]: HOSTING_OFFER_PERKS_STARTER,
    default: HOSTING_OFFER_PERKS_BUSINESS,
  };

  return mapKeyValue(HOSTING_OFFER_PERK_MAP, key);
};
