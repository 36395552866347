export enum Cookie {
  DISABLED_FEEDBACK = 'hostinger-hpanel-feedback-disabled',
  DISABLED_PRO_PANEL_FEEDBACK = 'pro-panel-feedback-disabled',
  LANGUAGE = 'language',
  SKIP_DOMAIN_REGISTRATION_ON_START = 'skip_domain_registration_onstart',
  HIDE_OLD_DASHBOARD_DISCLAIMER = 'hostinger-hpanel-hide-old-dashboard-disclaimer',
  BRAND_ID = 'hostinger-hpanel-brand-id',
  HPANEL_REDIRECT = 'hpanel-redirect',
  WEBSITES_PAGE_NAME = 'websites-page-name',
  SHOW_NEW_DOMAIN_REGISTRATION_FLOW = 'show-new-domain-registration-flow',
  SERVICE_EXPIRATION_REMINDER_CLOSED = 'hostinger-hpanel-service-expiration-reminder-closed',
  PLAYGROUND = 'hostinger-enable-playground',
  PROMOTE_TWO_FACTOR = 'promote-two-factor',
  HIDE_REFERRAL_BANNER = 'hide-referral-banner',
  DISABLE_DOMAIN_REGISTRATION = 'disable-domain-registration',
  DISABLE_DOMAIN_TRANSFER = 'disable-domain-transfer',
  DISABLE_DOMAIN_MOVE = 'disable-domain-move',
  PRO_PANEL_INTRO_VIDEO_SEEN = 'pro-panel-intro-video-seen',
  ACCESS_MANAGER_TOKEN = 'access-impersonator-token',
  ONBOARDING_V2 = 'onboarding-v2',
  JWT = 'bearer-token',
  JWT_NOT_STALE = 'jwtNS',
  REFRESHED_CVC_IPS = 'refreshed-cvc-ips',
  AI_SEARCH = 'ai-search',
  EMAIL_CONNECT_DOMAIN = 'email-connect-domain-v2',
  WORDPRESS_PREVIEW = 'wordpress-preview',
  VPS_MALWARE_SCANNER = 'vps-malware-scanner',
  PHISHING_BANNER_CLOSED = 'phishing-banner-closed',
  SERVER_TRANSFER_QA = 'server-transfer-qa',
  SHOW_BLACK_FRIDAY_CAMPAIGN = 'show-black-friday-campaign',
  SHOW_CYBER_WEEK_CAMPAIGN = 'show-cyber-week-campaign',
  SHOW_NEW_YEAR_CAMPAIGN = 'show-new-year-campaign',
  WP_PRESET_CREATION = 'wp-presets',
  MANAGED_SERVICES_MODAL_CLOSED = 'managed-services-modal-closed',
  HIDE_NETWORK_NOTIFICATION = 'hide-network-notification',
  SHOW_MANAGED_DOMAINS = 'show-managed-domains',
  HIDE_ANALYTICS_DISCLAIMER = 'hide-analytics-disclaimer',
  FEEDBACK_CLOSED = 'feedback-closed',
  PRO_PANEL_MERGE_MODAL_CLOSED = 'pro-panel-merge-modal-closed',
  GA_ID = '_ga',
  ENABLE_WEBSITES_V2 = 'enable-websites-v2',
  E2E_TEST_ID = 'e2e-test-id',
  E2E_TEST_BYPASS_RISK_INDICATOR = 'e2e-test-bypass-risk-indicator',
  SHOW_REFERRAL_BANNER = 'show-referral-banner',
  WORDPRESS_UPDATES_TAB_ENABLED = 'wordpress-updates-tab-enabled',
  WORDPRESS_AI_TROUBLESHOOTER_DISCLAIMER = 'wordpress-ai-troubleshooter-disclaimer',
  WEBSITES_OWNERSHIP_ALLOW_TO_TRANSFER_NEW = 'allow-to-transfer-new-websites',
  H5G_ONBOARDING_CLOSED = 'h5g-onboarding-closed',
  PRESETS_OFFER_CLOSED = 'presets-offer-closed',
  LATEST_ORDER_TOKEN = 'latest-order-token',
  REFERRALS_CLOUD_POPOVER_CLOSED = 'referrals-cloud-popover-closed',
  DISABLED_H5H_FEEDBACK = 'h5h-feedback-disabled',
  HIDE_TAGS_IN_WEBSITES_LIST = 'hide-tags-in-websites-list',
  ENABLE_REFERRALS_V2 = 'enable-referrals-v2',
  SHOW_SUMMER_SALE_CAMPAIGN = 'show-summer-sale-campaign',
  VPS_ONBOARDING_OS = 'vps-onboarding-os',
  ADDONS_ENABLED = 'addons-enabled',
  ONBOARDING_WP_REDIRECT = 'onboarding-wp-redirect',
  DEVELOPER_TOOLS_MONITORING_INTRODUCTION_CLOSED = 'developer-tools-monitoring-introduction-closed',
  REMEMBER_LOGIN_TOKEN = 'remember_login_token',
  REFERRALS_SHOW_CSAT_ON_NEXT_VISIT = 'referrals-show-csat-on-next-visit',
  PURCHASE_FLOW_INSTALL = 'vps-purchase-flow-install',
  HASOFFERS_SESSION = 'hasoffers_session',
  IRCLICKID = 'irclickid',
  CLIENT_ID = 'client_id',
  SESSION_ID = 'session_id',
  WP_AI_BUILDER_VISIBLE = 'wp-ai-builder-visible',
  LICENSE_BANNER_CLOSED = 'license-banner-closed',
  REFERRALS_CUSTOM_COMMISSION_ID = 'referrals-custom-commission-id',
  VPS_ONBOARDING_SURVEY_SKIP = 'vps-onboarding-survey-skip',
  COOKIE_CONSENT = 'cookie_consent',
  DEVELOPER_TOOLS_INTRODUCTION_CLOSED = 'developer-tools-introduction-closed',
  SHOW_SLUG_SELECTION = 'show-slug-selection',
  SLUG_LANGUAGE_SELECTED = 'slug-language-selected',
}
