import { isEmpty } from 'lodash';
import Pusher from 'pusher-js';

import { useModal } from '@/composables';
import { useWebsiteReportActions } from '@/composables/developerTools/useWebsiteReportActions';
import { router } from '@/router';
import store from '@/store/index';
import {
  useAiTroubleshootStore,
  useOrdersStore,
  useVpsBackupStore,
  useVpsFirewallStore,
  useVpsMalwareScannerStore,
  useVpsNotificationStore,
  useVpsServerStore,
  useVpsSnapshotStore,
  useVpsSshStore,
} from '@/stores';
import { useFreeEmailServiceStore } from '@/stores/email/freeEmailServiceStore';
import { Error, Pusher as PusherEnum, Route } from '@/types';
import { toASCII, toUnicode } from '@/utils/helpers';
import { getJwtToken } from '@/utils/helpers/authHelpers';
import handleHResourcesAction from '@/utils/services/PusherWorker/serviceActions/handleHResourcesAction';
import handleWordpressStagingAction from '@/utils/services/PusherWorker/serviceActions/handleWordpressStagingAction';
import { errorLogger } from '@/utils/services/errorLogging';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { $t } from '@/utils/services/i18nService';
import { snakeToCamelObj } from '@/utils/services/namingConventionsService';

const HMAIL_ORDER_COMPLETED = 'orderUpgrade.completed';

let connectionCount = 0;

const BROADCASTING_AUTH_API = `${process.env.VITE_API_INTEGRATIONS}/broadcasting/auth`;

const connectToPusher = function (userId, token = null) {
  const clientToken = getJwtToken();

  if (connectionCount > 3 || !clientToken || (token && token === clientToken)) {
    return;
  }

  const pusher = new Pusher(process.env.VITE_PUSHER_API_KEY, {
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: `${BROADCASTING_AUTH_API}?type=app`,
    auth: {
      headers: {
        Authorization: clientToken,
      },
    },
  });
  const generalChannel = pusher.subscribe(`private-hostinger-client-${userId}`);

  generalChannel.bind_global(handlePusherAction);

  [generalChannel].forEach((channel) => {
    channel.bind('pusher:subscription_error', (err) => {
      channel.unbind_all();
      pusher.unsubscribe(`private-hostinger-client-${userId}`);
      pusher.disconnect();
      if (err && (err === 401 || err.status === 401)) {
        connectionCount++;
        setTimeout(() => {
          connectToPusher(userId, clientToken);
        }, 2000);
      }
    });
  });
};

const PUSHER_PREFIX_CONFIG = {
  hvps: (event, data) => {
    handleHvpsAction(event, data);
  },
  hresources: (event, data) => {
    handleHResourcesAction(event, data);
  },
  hbilling: (event, data) => {
    handlehBillingAction(event, data);
  },
  hmail: (event, data) => {
    handlehMailAction(event, data);
  },
  website: (event, data) => {
    handleWebsiteAction(event, data);
  },
};
const PUSHER_PREFIX_SEPARATOR = '.';

const handlePusherAction = (event, data) => {
  const [eventPrefix, ...restOfEvent] = event.split(PUSHER_PREFIX_SEPARATOR);

  const eventName = restOfEvent.join(PUSHER_PREFIX_SEPARATOR);

  const pusherHandler = PUSHER_PREFIX_CONFIG[eventPrefix];

  if (pusherHandler) {
    pusherHandler(eventName, data);

    return;
  }
  errorLogger.setTag('pusherEventName', event);
  errorLogger.addBreadcrumb({
    message: 'Pusher event received',
    data: { event, body: data.body, variables: data.variables },
  });
  handleGeneralAction(event, data);
  errorLogger.setTag('pusherEventName', null);
};

const handleGeneralAction = (event, data) => {
  if (event.startsWith('wordpress_staging_')) {
    handleWordpressStagingAction(event, data);
  }

  if (event === 'hosting_website_remove') {
    const orderStore = useOrdersStore();
    const message = data.body.replace('request.domain', 'requestDomain');
    const requestDomain = data.variables['request.domain'];
    orderStore.checkIsOrderReloadRequired(requestDomain);

    toastr.s($t(message, { requestDomain }));
  }
  if (event === 'account_transferred_out') {
    toastr.s($t('The server transfer was successful'));
    store.dispatch('hostingOrdersDetailsIndex');
    store.dispatch('hostingAccountsDetailsIndex');
  }
  if (
    [
      'hosting_ssl_install_lifetime',
      'hosting_ssl_install_lifetime_failed',
    ].includes(event)
  ) {
    data.body = data.body.replace('request.domain', 'requestDomain');
    if (event === 'hosting_ssl_install_lifetime') {
      if (!data.variables.requestDomain) {
        data.variables = {
          requestDomain: toUnicode(data.variables['request.domain']),
        };
      }
    } else {
      const resultMessage = $t(data.variables['result.message']);
      data.variables = {
        requestDomain: toUnicode(data.variables['request.domain']),
      };
      let title = $t(data.body, data.variables);
      if (title.includes('requestDomain')) {
        title = $t(data.body, data.variables);
      }
      toastr.e(title, {
        text: resultMessage,
      });
    }

    store.commit('TOGGLE_DATA_UPDATE');
    store.dispatch('sslCertificateIndex');
  }

  if (event === PusherEnum.Event.CPanel.SSH_STATUS) {
    setTimeout(async () => {
      await store.dispatch(
        'cpanel/getCPanelDetailsByServiceId',
        data.message?.service_id,
      );
      toastr.s($t('cPanel SSH status is updated'));
    }, 1000);
  }
};

const handleHvpsAction = async (event, data) => {
  const serverStore = useVpsServerStore();
  const backupStore = useVpsBackupStore();
  const snapshotStore = useVpsSnapshotStore();
  const sshStore = useVpsSshStore();
  const firewallStore = useVpsFirewallStore();
  const vpsMalwareScannerStore = useVpsMalwareScannerStore();

  const action = data?.action;

  const notificationStore = useVpsNotificationStore();

  if (isEmpty(action) || !['success', 'error'].includes(action.state)) return;

  const serverId = action.virtual_machine_id;

  if (action.state === 'success') {
    switch (event) {
      case PusherEnum.Event.VirtualMachine.SNAPSHOT_CREATE:
      case PusherEnum.Event.VirtualMachine.SNAPSHOT_RESTORE:
      case PusherEnum.Event.VirtualMachine.SNAPSHOT_DELETE:
        snapshotStore.fetchServerSnapshots(serverId);
        break;
      case PusherEnum.Event.VirtualMachine.PUBLIC_KEY_CREATED:
        toastr.s($t('v2.ssh_key.toastr.add_success'));
        sshStore.fetchSshKeys(serverId, true);
        break;
      case PusherEnum.Event.VirtualMachine.PUBLIC_KEY_REMOVE:
        toastr.s($t('v2.ssh_key.toastr.delete_success'));
        sshStore.fetchSshKeys(serverId, true);
        break;
      case PusherEnum.Event.VirtualMachine.BACKUP_RESTORE:
        backupStore.fetchServerBackups({ serverId });
        serverStore.fetchServerById({ serverId, immediate: true });
        break;
      case PusherEnum.Event.VirtualMachine.CREATED:
        const response = await serverStore.fetchServerById({
          serverId,
          immediate: true,
        });
        const errorStatus = response?.error?.response?.status;

        if (errorStatus === Error.StatusCode.FORBIDDEN) {
          toastr.w(
            'VPS was created successfully. In order to manage it, you need additional permissions.',
          );
          router.push({ name: Route.Base.HOME });
        }
        break;
      case PusherEnum.Event.VirtualMachine.BACKUP_CREATING:
        serverStore.setServerLock(serverId, true);
        serverStore.setUpdatingServer({ serverId });
        break;
      case PusherEnum.Event.VirtualMachine.FIREWALL_SYNCED:
        await firewallStore.getGroups(true, true);

        toastr.s($t('Your firewall rule was synchronized successfully'));
        serverStore.fetchServerById({ serverId, immediate: true });

        break;
      case PusherEnum.Event.VirtualMachine.CPU_LIMIT:
        toastr.s($t('VPS limitations were removed successfully'));
        serverStore.fetchServerById({ serverId, immediate: true });
        break;
      case PusherEnum.Event.VirtualMachine.RESTART:
      case PusherEnum.Event.VirtualMachine.RECREATED:
      case PusherEnum.Event.VirtualMachine.START:
      case PusherEnum.Event.VirtualMachine.NAMESERVERS_UPDATE:
      case PusherEnum.Event.VirtualMachine.STOP:
      case PusherEnum.Event.VirtualMachine.HOSTNAME_UPDATE:
      case PusherEnum.Event.VirtualMachine.TUN_TAP:
      case PusherEnum.Event.VirtualMachine.DISK_QUOTA:
      case PusherEnum.Event.VirtualMachine.NFSD:
      case PusherEnum.Event.VirtualMachine.TRANSFER:
      case PusherEnum.Event.VirtualMachine.TRANSFERED:
      case PusherEnum.Event.VirtualMachine.MONARX_INSTALLED:
      case PusherEnum.Event.VirtualMachine.MONARX_SYNC:
      case PusherEnum.Event.VirtualMachine.DAILY_BACKUPS:
      case PusherEnum.Event.VirtualMachine.RECOVERY:
      case PusherEnum.Event.VirtualMachine.STOP_RECOVERY:
      case PusherEnum.Event.VirtualMachine.BACKUP_CREATED:
      case PusherEnum.Event.VirtualMachine.IP_CHANGED:
        serverStore.fetchServerById({ serverId, immediate: true });
        break;
      case PusherEnum.Event.VirtualMachine.MONARX_INSTALL_FAILED:
        vpsMalwareScannerStore.setFailedInstallation(true);
        break;
      case PusherEnum.Event.VirtualMachine.MONARX_SYNC_FAILED:
        toastr.e($t('v2.vps_malware_scanner.sync.failed_message'));
        break;
      case PusherEnum.Event.VirtualMachine.LICENSE_CREATED:
        const title = action.license?.panel_type;

        if (title) {
          toastr.s(
            $t('Your {title} license is installed successfully', { title }),
          );
        }

        serverStore.fetchServerById({ serverId, immediate: true });
        break;
      case PusherEnum.Event.VirtualMachine.ORDER_UPDATED:
        serverStore.removeUpgradingServer(serverId);
    }
  } else if (action.state === 'error') {
    serverStore.fetchServerById({ serverId, immediate: true });
  }

  if (event === PusherEnum.Event.VirtualMachine.BACKUP_CREATING) return;

  serverStore.removeUpdatingServer(serverId);
  serverStore.setServerLock(serverId, false);

  notificationStore.addServerNotification({
    id: action.id,
    serverId: action.virtual_machine_id,
    name: action.name,
    state: action.state,
  });
};

const handlehBillingAction = (event, data) => {
  if (event === 'order.completed') {
    store.commit('orders/ADD_OR_UPDATE_ORDER_BY_TOKEN', snakeToCamelObj(data));

    return;
  }

  if (event === 'subscription.cancelled') {
    store.commit(
      'subscriptions/UPDATE_SUBSCRIPTION_BY_ID',
      snakeToCamelObj(data),
    );

    return;
  }
};

const handleWebsiteAction = (event, data) => {
  if (event === PusherEnum.Event.Website.AI_AUTO_FIX_FINISHED) {
    const aiTroubleshootStore = useAiTroubleshootStore();
    aiTroubleshootStore.handleTroubleshootActionCompleteFromPusher(
      snakeToCamelObj(data),
    );
  }

  if (event === PusherEnum.Event.Website.WEBSITE_REPORT_GENERATED) {
    const createReport = useWebsiteReportActions();
    const email = data.variables['report.email'];
    const domain = data.variables['report.domain'];
    const documentKey = data.variables['report.document_key'];

    if (email) {
      createReport.showSentViaEmailToastrAndRefetchData({ domain, email });

      return;
    }

    createReport.showGeneratedToastrAndRefetchData({ domain, documentKey });
  }
};

const handlehMailAction = async (event, data) => {
  if (event === HMAIL_ORDER_COMPLETED) {
    const updatedOrder = snakeToCamelObj(data);
    store.commit('emails/UPDATE_HOSTINGER_EMAIL_ORDER', updatedOrder);

    await store.dispatch('fetchHostingOrders');

    store.dispatch('fetchEmailDetails', {
      domain: updatedOrder.domain,
      reload: true,
    });

    store.dispatch('fetchHostingEmailsAccounts', {
      domain: updatedOrder.domain,
      reload: true,
    });

    store.dispatch('emails/fetchHostingerEmailById', updatedOrder.resourceId);
    toastr.s($t('Email plan was successfully upgraded'));
  }

  if (event === PusherEnum.Event.Email.TRIAL_ORDER_CREATED) {
    const { closeModal } = useModal();

    closeModal();

    router.replace({ query: undefined });

    const updatedOrder = snakeToCamelObj(data);

    if (!updatedOrder?.domain) {
      errorLogger.logError('Domain is missing for pusher event');

      return;
    }

    router.push({
      name: Route.Email.HMAIL_MANAGEMENT,
      params: { domain: toASCII(updatedOrder.domain) },
    });
  }
  if (
    [
      PusherEnum.Event.Email.FREE_HMAIL_CREATED,
      PusherEnum.Event.Email.FREE_TITAN_CREATED,
    ].includes(event)
  ) {
    const freeEmailServiceStore = useFreeEmailServiceStore();
    freeEmailServiceStore.setCreatedEmailPusherResponse(data);
  }
};

export default connectToPusher;
